<template>
  <div class="map-block">
    <yandex-map
      v-if="mapMark"
      :key="'map3-' + updaterKey"
      ref="map"
      class="map"
      :class="{ 'auto-width': autoWidth }"
      :settings="settings"
      :coords="coords"
      :zoom="5"
      @boundschange="rotateIcon()"
    >
      <ymap-marker
        v-for="(item, index) in mapMark"
        :key="index"
        :marker-id="index"
        v-on:click="(tracker_index = index), onClickMarker('tracker_index', index)"
        :coords="item"
        icon-class="icon-class"
        :icon="{
          layout: 'default#imageWithContent',
          imageHref: `${tracker_index === index ? '../map-bus-yellow.png' : '../map-bus.png'}`,
          imageSize: [30, 15],
          imageOffset: [-15, -7],
          contentOffset: [-60, 4.8],
          contentLayout: `<div class='map-icon-${index}' style='width: 60px;height: 5px;background: linear-gradient(to left, ${tracker_index === index ? 'yellow' : 'greenyellow' }, transparent);'></div>`,
        }"
        @click="showMarkerData(index)"
      />
    </yandex-map>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { myMixin } from "./map-function.js";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

const settings = {
  apiKey: process.env.VUE_APP_YMAPS_APIKEY,
  lang: "ru_RU",
};

export default {
  name: "HomeMap",
  mixins: [myMixin],
  props: {
    mapMark: {
      type: Array,
      default: () => [],
    },
    clickPoint: {
      type: Object,
      default: () => {},
    },
    selectedTrackerId: {
      type: Number,
      default: null,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    autoWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      updaterKey: 0,
      coords:
        this.mapMark && this.mapMark.length
          ? this.mapMark[0]
          : [50.18978384100712, 71.68672979805159],
      settings,
      pointData: null,
      clickInfo: null,
      iconList: 0,
      iconTrack: null,
      tracker_index: null,
    };
  },
  computed: {
    ...mapState(["trackerId", "mapTransports"]),
    balloonLoaderTemplate() {
      return `
        <div class="map-info">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      `;
    },
    balloonClickTemplate() {
      return `
        <div class="map-info">
          <div v-else>${this.clickInfo}</div>
        </div>
      `;
    },
    balloonTemplate() {
      return `
        <div class="map-info">
          <div class="map-info__name flex">
            <div class="map-info__name-box">
              <h2 class="map-info__name-title">${this.pointData.label}</h2>
              <p class="map-info__name-text flex">
                <img class="map-info__name-img" src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png" alt="">
                null
              </p>
            </div>
            <button class="map-info__name-btn default-btn">null</button>
          </div>
          <ul class="map-info__situation flex">
            <li class="map-info__situation-item flex">
              <div class="map-info__situation-box">
                <p class="map-info__situation-name">Скорость</p>
                <strong class="map-info__situation-value">${
                  this.pointData.coordination.speed
                } км/ч</strong>
              </div>
              <img class="map-info__situation-img" src="https://s1.iconbird.com/ico/2013/8/429/w512h5121377939972185093dashboardspeedstreamline.png" alt="">
            </li>
            <li class="map-info__situation-item flex">
              <div class="map-info__situation-box">
                <p class="map-info__situation-name">Топливо</p>
                  <strong class="map-info__situation-value">${
                    this.trackerId ? this.trackerId.response.inputs[0].value : 0
                  } л</strong>
              </div>
              <img class="map-info__situation-img" src="https://w7.pngwing.com/pngs/380/472/png-transparent-car-filling-station-gasoline-computer-icons-fuel-gas-text-car-transport.png" alt="">
            </li>
            <li class="map-info__situation-item flex">
              <div class="map-info__situation-box">
                <p class="map-info__situation-name">Температура</p>
                <strong class="map-info__situation-value">${this.trackerId.temp} °С</strong>
              </div>
              <img class="map-info__situation-img" src="https://toppng.com/uploads/preview/temperature-free-icon-temperature-icon-free-11553469636smayyrvo21.png" alt="">
            </li>
            <li class="map-info__situation-item flex">
              <div class="map-info__situation-box">
                <p class="map-info__situation-name">Влажность</p>
                <strong class="map-info__situation-value">${this.trackerId.hump}</strong>
              </div>
              <img class="map-info__situation-img" src="https://toppng.com/uploads/preview/temperature-free-icon-temperature-icon-free-11553469636smayyrvo21.png" alt="">
            </li>
          </ul>
            <li class="map-info__number-item flex">
              <p class="map-info__number-text">Остаток груза</p>
              <p class="map-info__number-value">${this.pointData.order_status?.order?.weight}</p>
            </li>
<!--            <li class="map-info__number-item flex">-->
<!--              <p class="map-info__number-text">Не запланированные остановки</p>-->
<!--              <p class="map-info__number-value"></p>-->
<!--            </li>-->
            <li class="map-info__number-item flex">
              <p class="map-info__number-text">Местоположение</p>
              <p class="map-info__number-value">${this.pointData.order_status?.order?.from}</p>
            </li>
          </ul>
        </div>
      `;
    },
  },
  watch: {
    selectedTrackerId() {
      if (this.indexFromTrackerId() > 0) {
        if (this.modal) {
          this.showMarkerData();
          this.$refs.map.myMap.balloon.open(
            this.mapMark[this.indexFromTrackerId()],
            this.balloonLoaderTemplate
          );
        }
        if (!this.modal) {
          this.clickInfo = this.clickPoint.name;
          this.$refs.map.myMap.balloon.open(
            this.mapMark[this.indexFromTrackerId()],
            this.balloonClickTemplate
          );
        }
      }
    },
  },
  created() {
    async () => {
      await this.$store.dispatch("getMapTransports").then(() => {
        if (this.indexFromTrackerId() > 0) {
          if (this.clickPoint) {
            if (this.modal) {
              this.showMarkerData();
            }
            if (!this.modal) {
              this.clickInfo = this.clickPoint.name;
              this.$refs.map.myMap.balloon.open(
                this.mapMark[this.indexFromTrackerId()],
                this.balloonClickTemplate
              );
            }
          }
        }
        this.rotateIcon(3500);
        this.$nextTick(() => {
          this.updateComponents();
        });
      });
    };
  },
  mounted() {
    // 
  },
  methods: {
    updateComponents() {
      if (this.updaterKey > 9999) {
        this.updaterKey = 0;
      } else this.updaterKey++;
      this.$emit("componentsUpdate", this.updaterKey);
    },
    indexFromTrackerId() {
      const index = this.mapTransports.findIndex((o) => o.id === this.selectedTrackerId);
      if (index === -1) {
        this.$emit("noTrackerFound");
      }
      return index;
    },
    async showMarkerData() {
      const index = this.indexFromTrackerId();
      if (index !== -1) {
        if (this.mapTransports) this.pointData = this.mapTransports[index];
        if (this.pointData) {
          await this.trackerProduct();
          await this.$refs.map.myMap.balloon.open(this.mapMark[index], this.balloonTemplate);
          this.$store.commit("setZoneShowData", this.pointData);
          this.$emit("click", this.pointData);
        }
      }
    },
    async trackerProduct() {
      await this.$store.dispatch("getTrackerId", {
        tracker_id: this.pointData.id,
      });
    },
    rotateIcon(wait = 1000) {
      setTimeout(() => {
        this.iconList = document.querySelectorAll(".ymaps-2-1-79-image-with-content");
        this.mapTransports.forEach((el, i) => {
          this.iconTrack = document.querySelector(`.map-icon-${i}`);
          if (this.iconTrack && this.mapTransports[i].coordination.speed === 0) {
            this.iconTrack.style.display = "none";
          }
          if (this.iconList[i]) {
            this.mapTransports.forEach((el, index) => {
              this.iconTrack = this.iconList[i].querySelector(`.map-icon-${index}`);
              if (
                index < this.mapTransports.length - 1 &&
                this.iconTrack &&
                el.order_status.order
              ) {
                let lat = el.order_status.order.from_lat - el.order_status.order.to_lat;
                let long = el.order_status.order.from_long - el.order_status.order.to_long;
                // let lat = 42.34234917175733 - 66.36589483728025
                // let long = 69.58576209485047 - 138.48854154299195
                let c = Math.sqrt(Math.pow(lat, 2) + Math.pow(long, 2));
                let A = (Math.acos(long / c) * 180) / Math.PI;
                if (long >= 0 && lat < 0) {
                  A > 90
                    ? (this.iconList[i].style.transform = `rotate(${A + 90}deg)`)
                    : (this.iconList[i].style.transform = `rotate(${A + 180}deg)`);
                }
                if (long < 0 && lat < 0) {
                  A > 90
                    ? (this.iconList[i].style.transform = `rotate(${A + 180}deg)`)
                    : (this.iconList[i].style.transform = `rotate(${A - 90}deg)`);
                }
                if (long < 0 && lat >= 0) {
                  A > 90
                    ? (this.iconList[i].style.transform = `rotate(${A - 90}deg)`)
                    : (this.iconList[i].style.transform = `rotate(${A}deg)`);
                }
                if (long >= 0 && lat >= 0) {
                  A > 90
                    ? (this.iconList[i].style.transform = `rotate(${A}deg)`)
                    : (this.iconList[i].style.transform = `rotate(${A + 90}deg)`);
                }
              }
            });
          }
        });
      }, wait);
    },
    onClickMarker(key, params) {
      var data = this.mapTransports[params].transport?.id
      if(data)
        this.$emit('onClickMarker', {
          key,
          data
        })
    },
    onClickTransport(id) {
      var ind;
      this.mapTransports.map((o, index) => {
        if (o.transport?.id === id) {
          ind = index;
        }
      });
      if(ind)
        this.tracker_index = ind;
      else
        this.tracker_index = null;
    }
  },
  components: {
    yandexMap,
    ymapMarker,
  },
};
</script>

<style scoped>
.map-block {
  width: 100%;
  height: 100%;
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
}
.auto-width {
  width: 100%;
}
</style>
<style>
.ymaps-2-1-79-controls__control,
.ymaps-2-1-79-controls__control_toolbar:first-child,
.ymaps-2-1-79-controls__control_toolbar:nth-child(2) {
  display: none !important;
}
</style>
