var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-block"},[(_vm.mapMark)?_c('yandex-map',{key:'map3-' + _vm.updaterKey,ref:"map",staticClass:"map",class:{ 'auto-width': _vm.autoWidth },attrs:{"settings":_vm.settings,"coords":_vm.coords,"zoom":5},on:{"boundschange":function($event){return _vm.rotateIcon()}}},_vm._l((_vm.mapMark),function(item,index){return _c('ymap-marker',{key:index,attrs:{"marker-id":index,"coords":item,"icon-class":"icon-class","icon":{
        layout: 'default#imageWithContent',
        imageHref: `${_vm.tracker_index === index ? '../map-bus-yellow.png' : '../map-bus.png'}`,
        imageSize: [30, 15],
        imageOffset: [-15, -7],
        contentOffset: [-60, 4.8],
        contentLayout: `<div class='map-icon-${index}' style='width: 60px;height: 5px;background: linear-gradient(to left, ${_vm.tracker_index === index ? 'yellow' : 'greenyellow' }, transparent);'></div>`,
      }},on:{"click":[function($event){(_vm.tracker_index = index), _vm.onClickMarker('tracker_index', index)},function($event){return _vm.showMarkerData(index)}]}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }